import React from "react"
import { Link } from "gatsby"
import Svg from "../components/ui/svg"
import Seo from "../components/seo"

/**
 * @title 404
 * @return {React.Component} Component page
 * @description 404 Error Page per i contenuti non presenti. Integrato il componente SEO
 */

const NotFoundPage = () => (
  
  <>

    <Seo title="404: Not found" description="Pagina non trovata." />

    <div data-sal="zoom-in" data-sal-duration="500" 
    className={"flex flex-col justify-center items-center pt-[10vh] bg-white "+
    "dark:bg-gradient-to-b dark:from-gray-400 dark:to-secondary-600 "}>
      <Svg.Page404 className="w-5/6 lg:w-4/6 xl:w-3/6 max-h-screen pt-10" title="404" />
      <div className="flex flex-col justify-center items-center pt-20">
        <p className="font-bold text-xl sm:text-3xl text-secondary-600 dark:text-secondary-100">Ops... qualcosa è andato storto</p>
        <Link to="/" className="pt-3" >
          <div className="bg-primary-700 hover:bg-primary-500 hover:scale-x-105 font-bold py-2 px-3 rounded-2xl 
            shadow-lg hover:shadow-xl transition duration-200 text-center text-white">
            Torna alla homepage
          </div>
        </Link>
      </div>
    </div>  

  </>
)

export default NotFoundPage
